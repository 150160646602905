import * as React from "react";
import styled from "styled-components/native";
import { observer, inject } from "mobx-react";
import { PlayerStore } from "../../store/player.store";
import { Platform, TouchableOpacity } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

const cursor = Platform.select({
  default: null,
  web: "cursor: pointer"
});

export interface IPlaylistProps {
  playerStore?: PlayerStore;
}

const Container = styled(ScrollView)`
  flex: 1;
  border: 2px solid whitesmoke;
`;

const ListItem = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-self: center;
  height: 50;
  width: 80%;
  margin-top: 10;
  align-items: center;
  justify-content: center;
  border-bottom-width: 2px;
  /* border-bottom-style: solid; */
  border-bottom-color: whitesmoke;
  ${cursor}
`;

const Title = styled.Text`
  font-family: "Montserrat-SemiBold";
  font-size: 14;
  line-height: 14;
  color: whitesmoke;
  text-align: center;
`;

@inject("playerStore")
@observer
export default class Playlist extends React.Component<IPlaylistProps> {
  render() {
    const { currentPlaylist } = this.props.playerStore;
    return (
      <Container>
        {currentPlaylist.songs.map(song => (
          <ListItem
            onPress={() => this.props.playerStore.setCurrentSong(song.id)}
            key={song.id}
          >
            <Title>{song.title}</Title>
            <Title>|</Title>
            <Title>{song.artist}</Title>
          </ListItem>
        ))}
      </Container>
    );
  }
}
