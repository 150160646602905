import React from "react";
import { Provider } from "mobx-react";
import { AppLoading } from "expo";
import * as Font from "expo-font";

import AppContainer from "./src/navigation";

import { StatusBar } from "react-native";
import PlayerStore from "./src/store/player.store";

interface State {
  isReady: Boolean;
}

const store = {
  playerStore: PlayerStore
};

export default class App extends React.Component<{}, State> {
  public state: State = { isReady: false };

  async componentDidMount() {
    await this._loadFonts();
  }

  async _loadFonts() {
    await Font.loadAsync({
      Montserrat: require("./assets/Montserrat/Montserrat-Regular.ttf"),
      "Montserrat-SemiBold": require("./assets/Montserrat/Montserrat-SemiBold.ttf")
    });
    this.setState({
      isReady: true
    });
  }
  render() {
    if (!this.state.isReady) {
      return <AppLoading />;
    }

    return (
      <Provider {...store}>
        {/* <StatusBar barStyle="light-content" /> */}
        <AppContainer />
      </Provider>
    );
  }
}
