import React, { Component } from "react";
import {
  Text,
  Platform,
  View,
  SafeAreaView,
  TouchableOpacity
} from "react-native";
import { observer, inject } from "mobx-react";
import styled from "styled-components/native";
import * as Animatable from "react-native-animatable";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import Player from "../components/player";
import { PlayerStore } from "../store/player.store";
import Playlist from "../components/playlist";

const flexDirection = Platform.select({
  default: "column",
  web: "row"
});

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: black;
  justify-content: space-between;
  padding: 0;
  flex-direction: row;
`;

const StyledView = styled.View`
  flex: 3;
  background-color: black;
  justify-content: center;
  align-items: center;
  flex-direction: ${flexDirection};
`;

interface IHomeProps {
  playerStore: PlayerStore;
}

interface IHomeState {
  isCreatePlaylistVisible: boolean;
}

@inject("playerStore")
@observer
export default class Home extends Component<IHomeProps, IHomeState> {
  constructor(props: IHomeProps) {
    super(props);
    this.state = {
      isCreatePlaylistVisible: false
    };
  }
  render() {
    return (
      <Container>
        {this.state.isCreatePlaylistVisible && (
          <Animatable.View
            style={{
              width: "100%",
              height: 50,
              backgroundColor: "white",
              position: "absolute",
              alignSelf: "center",
              top: 0,
              bottom: 0,
              zIndex: 100
            }}
            animation="fadeInDown"
          >
            <Text>Add SOng</Text>
          </Animatable.View>
        )}
        <StyledView>
          <Animatable.View
            animation="fadeIn"
            style={{
              flex: 2,
              flexDirection: "column",
              justifyContent: Platform.select({
                default: "flex-end",
                web: ""
              }),
              alignSelf: Platform.select({
                default: "center",
                web: "flex-start"
              }),
              height: "100%"
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <MaterialCommunityIcons
                name="playlist-edit"
                size={30}
                color="whitesmoke"
              ></MaterialCommunityIcons>
              <Text
                onPress={() =>
                  this.setState({
                    isCreatePlaylistVisible: !this.state.isCreatePlaylistVisible
                  })
                }
                style={{
                  fontFamily: "Montserrat-SemiBold",
                  fontSize: 40,
                  lineHeight: 40,
                  textAlignVertical: "center",
                  color: "white",
                  textAlign: "center"
                }}
              >
                XITE
              </Text>
              <TouchableOpacity
                onPress={() =>
                  this.props.playerStore.addSongToCurrentPlaylist({
                    id: 10,
                    title: "test",
                    artist: "test",
                    url: "https://www.youtube.com/watch?v=d-XnJwcBpVo",
                    video_id: ""
                  })
                }
              >
                <MaterialCommunityIcons
                  name="playlist-plus"
                  size={30}
                  color="whitesmoke"
                ></MaterialCommunityIcons>
              </TouchableOpacity>
            </View>
            <Playlist />
          </Animatable.View>
          <Animatable.View
            style={{
              flex: 2,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%"
            }}
            animation="fadeIn"
          >
            <Player
              playbackStatus={status => console.log(status)}
              video={this.props.playerStore.currentSong}
            />
          </Animatable.View>
        </StyledView>
      </Container>
    );
  }
}
