import { createAppContainer, createSwitchNavigator } from "react-navigation";
import { createStackNavigator } from "react-navigation-stack";

import Home from "../screens/home";

import { Platform } from "react-native";
import { createBrowserApp } from "@react-navigation/web";

const routes = {
  Home: {
    screen: Home,
    path: "",
    navigationOptions: {
      header: null
    }
  }
  //   Top: {
  //     screen: TopPage,
  //     path: "top",
  //     navigationOptions: {
  //       header: null
  //     }
  //   }
};

const createApp = Platform.select({
  web: config => createBrowserApp(createSwitchNavigator(config)),
  default: config => createAppContainer(createStackNavigator(config))
});

export default createApp(routes);
