import React from "react";
import { Platform, View } from "react-native";
import ReactPlayer from "react-player";
import { Video } from "expo-av";
import { SongModel } from "../../store/player.store";
import { PlaybackStatus, Playback } from "expo-av/build/AV";
import { WebView } from "react-native-webview";
import { youtube_url_parser } from "../../utils";

interface IPlaybackStatus {
  video: SongModel;
  status: PlaybackStatus;
}

interface PlayerProps {
  video: SongModel;
  playbackStatus(status: PlaybackStatus): void;
}

const youtubeHTML = ({ id, autoplay, width, height }) => `
    <html>
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style>
            html, body {
                margin: 0;
                background: black;
                height: '${height}px';
                width: '${width}px';
            }
        </style>
    </head>
    <body>
        <div id="player"></div>
        <script>
        // load iframe player api code asynchronously
        // see docs https://developers.google.com/youtube/iframe_api_reference
        var tag = document.createElement('script')
        tag.src = "https://www.youtube.com/iframe_api"
        var firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        // create youtube iframe when api is ready
        var player;
        function onYouTubeIframeAPIReady() {
            player = new YT.Player('player', {
                height: '${height}',
                width: '${width}',
                playerVars: {
                  autoplay: 1,
                  fs: 1,
                },
                videoId: '${id}',
                events: {
                  'onReady': onPlayerReady,
                  'onStateChange': onPlayerStateChange
                }
            })
            function onPlayerReady(event) {
              event.target.playVideo();
              
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    type: 'PLAYING',
                    duration: player.getDuration()
                }))
            }
            function onPlayerStateChange(event) {
                if (event.data === YT.PlayerState.PLAYING) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        type: 'PLAYING',
                        payload: {
                            duration: player.getDuration()
                        }
                    }))
                }
                if (event.data === YT.PlayerState.ENDED) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        type: 'ENDED'
                    }))
                }
                if (event.data === YT.PlayerState.PAUSED) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        type: 'PAUSED',
                        payload: {
                            duration: player.getDuration(),
                            elapsed: player.getCurrentTime()
                        }
                    }))
                }
            }
            // handle messages from react native
            document.addEventListener("message", function(event) {
                const action = JSON.parse(event.data)
                switch (action.type) {
                    case 'SET_SIZE':
                        player.setSize(action.payload.width, action.payload.height)
                        break
                }
            }, false)
        }
        </script>
    </body>
    </html>
`;

const Player: React.FC<PlayerProps> = props =>
  Platform.select({
    default: (
      <View
        style={{
          width: 300,
          height: 300
        }}
      >
        <WebView
          // userAgent="Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.90 Safari/537.36"
          scalesPageToFit={true}
          allowsInlineMediaPlayback={true}
          automaticallyAdjustContentInsets={false}
          mediaPlaybackRequiresUserAction={false}
          allowsFullscreenVideo={true}
          scrollEnabled={false}
          style={{
            width: 300
          }}
          source={{
            html: youtubeHTML({
              id: props.video.video_id,
              width: "300px",
              autoplay: true,
              height: "300px"
            })
          }}
          onMessage={({ nativeEvent: state }) => {
            console.log(state);
          }}
        />
      </View>
    ),
    web: <ReactPlayer url={props.video.url} playing />
  });

export default Player;
